import Vue from 'vue';

Vue.directive('drawerDrag', {
    bind(el, binding, vnode, oldVnode) {
        const minWidth = 596;
        const maxWidth = 1020;
        const dragDom = el.querySelector('.el-drawer');
        dragDom.style.overflow = 'auto';
        const resizeElL = document.createElement('div');
        dragDom.appendChild(resizeElL);
        resizeElL.style.cursor = 'w-resize';
        resizeElL.style.position = 'absolute';
        resizeElL.style.height = '100%';
        resizeElL.style.width = '10px';
        resizeElL.style.left = '0px';
        resizeElL.style.top = '0px';
        let elW, EloffsetLeft, clientX;
        const mousemove = e => {
            e.preventDefault();
            debugger
            let eClientX;
            if( document.getElementsByClassName('SXGA-style').length>0){
                eClientX = e.clientX * window.devicePixelRatio;
            }else{
                eClientX = e.clientX;
            }
         
            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
                let dragWith = 0;
                if (clientX > eClientX && dragDom.clientWidth <= maxWidth) {
                    dragWith = elW + (clientX - eClientX);
                    dragWith = dragWith > maxWidth ? maxWidth : dragWith;
                }
                if (clientX < eClientX && dragDom.clientWidth >= minWidth) {
                    dragWith = elW - (eClientX - clientX);
                    dragWith = dragWith < minWidth ? minWidth : dragWith;
                }
                if (dragWith !== 0) {
                    dragDom.style.width = dragWith + 'px';
                    if( document.getElementsByClassName('SXGA-style').length==0 ){
                        if(document.getElementsByClassName('SXGA-base').length==0){
                            document.getElementsByClassName('icon-style')[0].style.right = dragDom.style.width;
                        }else{
                            document.getElementsByClassName('icon-style')[0].style.right = document.querySelector('.right-menu-drawer .el-drawer').clientWidth - (document.getElementById('app').clientWidth - window.innerWidth);
                        }
                        document.getElementsByClassName('scene')[0].style.left = -( document.querySelector('.right-menu-drawer .el-drawer').clientWidth )/2+ 'px';
                    }else{
                        document.getElementsByClassName('scene')[0].style.left = -( document.querySelector('.right-menu-drawer .el-drawer').clientWidth )/2+ 'px';
                    }
                    let index = 0;
                    switch(localStorage.getItem('tabSelected')) {
                        case '备注':
                            index = 1;
                            break;
                        case '照片':
                            index = 3;
                            break;
                        case '分步':
                            index = 5;
                            break;
                        case '概况':
                            index = 7;
                            break;
                        default:
                            index = 9;
                    }
                    const translateX = (dragWith - 24)/10 * index - 24;
                    document.getElementsByClassName('el-tabs__active-bar')[0].style.transform = 'translateX('+ translateX +'px)';
                }
            }
        };

        resizeElL.addEventListener('mousedown', e => {
            elW = dragDom.clientWidth;
            EloffsetLeft = dragDom.offsetLeft;
            if( document.getElementsByClassName('SXGA-style').length > 0 ){
                clientX = e.clientX * window.devicePixelRatio;
            }else{
                clientX = e.clientX;
            }
         
            document.addEventListener('mousemove', mousemove, false);
        });
        document.addEventListener('mouseup', e => {
            document.removeEventListener('mousemove', mousemove, false);
        });
    }
});