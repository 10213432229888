<template>
  <div>
    <ul class="clearfix" :class='{"ul-bg":$store.state.model}'  :style="topValue">
      <li
        v-for="(item, i) in menus"
        :key="i"
        @click="choice.call(this, i, item.font)"
        @mouseover="item.isHover = true"
        @mouseout="item.isHover = false"
      >
        <div class="image">
          <img style="height: 100%; width: 100%" :src="item.isHover ? item.urlHover : item.url" v-if='!$store.state.model' />
          <img style="height: 100%; width: 100%" :src="item.isHover ? item.urlHover1 : item.url1" v-else />
        </div>
        <p :class='{"font-color":$store.state.model}'>{{ item.font }}</p>
      </li>
    </ul>

    <!-- <el-menu>
      <el-menu-item v-for="(item, i) in menus" :key="i" >
        <div class="image" @click="choice.call(this, i, item.font)">
          <img :src="item.url" />
        </div>
        <p>{{ item.font }}</p>
      </el-menu-item>
    </el-menu> -->
    <el-drawer
      v-drawerDrag
      class="right-menu-drawer"
      :class='{"bg-font":$store.state.model}'
      ref="drawer"
      :visible.sync="drawer"
      :direction="direction"
      :with-header="false"
      :modal="false"
      :show-close="true"
      :size="drawerWith"
      :before-close="handleClose"
    >
      <div class="icon-style" :style="leftValue" @click="closeModal">
        <!-- <i class="el-icon-caret-right"></i> -->
        <img
          :src="backImg.isHover ? backImg.hover : backImg.url"
          @mouseover="backImg.isHover = true"
          @mouseout="backImg.isHover = false"
        />
      </div>
      <el-tabs v-model="tabSelected" :stretch="true" @tab-click="handleClick">
        <el-tab-pane label="备注" name="备注">
          <rightMenuUpdate :planName="planName" />
        </el-tab-pane>
        <el-tab-pane label="照片" name="照片">
          <photo />
        </el-tab-pane>
        <el-tab-pane label="分步" name="分步">
          <stepTable :totalstep.sync="totalstep" :planIndext.sync="planIndext" />
        </el-tab-pane>
        <el-tab-pane label="概况" name="概况">
          <generalSituation />
        </el-tab-pane>
        <el-tab-pane label="反馈" name="反馈">
          <feedbackComponent ref="feedback" />
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>
<script>
import '@/util/drawerdrag';
import rightMenuUpdate from '@/views/common/zlkj/rightMenuUpdate';
import photo from '@/views/common/zlkj/rightMenu/photo';
import generalSituation from '@/views/common/zlkj/rightMenu/generalSituation';
import feedbackComponent from '@/views/common/zlkj/rightMenuFeedback';
import stepTable from '@/views/common/zlkj/rightMenu/stepTable';

export default {
  name: 'rightMenu',
  props: {
    planName: {
      type: String
    },
    totalstep: {
      type: Array
    },
    planIndext: {
      type: Number
    }
  },
  data() {
    return {
      backImg: {
        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/back_normal.png`),
        hover: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/back_hover.png`),
        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/右侧/back_normal.png`),
        hover: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/右侧/back_hover.png`),
        isHover: false
      },
      menus: [
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/remarks.png`), //显示的图片
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/remarks_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/右侧/remarks_normal.png`), //显示的图片
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/右侧/remarks_hover.png`),
          isHover: false,
          font: '备注' //显示的文字
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/data.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/data_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/data_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/data_hover.png`),
          isHover: false,
          font: '照片'
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/process.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/process_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/process_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/process_hover.png`),
          isHover: false,
          font: '分步'
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/Treatment.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/Treatment_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/Treatment_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/Treatment_hover.png`),
          isHover: false,
          font: '概况'
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/chat.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/chat_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/chat_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/chat_hover.png`),
          isHover: false,
          font: '反馈'
        }
      ],
      drawerWith: '596',
      tabSelected: '修改',
      direction: 'rtl',
      drawer: false,
      scrollTop: 0
    };
  },
  computed: {
    topValue() {
      if(this.$store.state.model){
        return { top: 104 - this.scrollTop + 'px', background: 'rgb(46, 47, 51)', 'z-index': 1 };

      }else{
      return { top: 104 - this.scrollTop + 'px', background: '#FFF', 'z-index': 1 };

      }
     
    },
    leftValue() {
      //return { right: 596 + 'px' };
      return '';
    }
  },
  watch: {
    tabSelected() {
      if (this.tabSelected === '反馈') {
        let vm = this;
        this.$nextTick(() => {
          vm.$refs.feedback.fileHistory = false;
          setTimeout(() => {
            vm.$refs.feedback.scrollToBottom();
          }, 100);
        });
      }
      localStorage.setItem('tabSelected', this.tabSelected);
    }
  },
  async created() {},
     mounted() { this.$store.commit('changeCopy',true)
    let vm = this;
    window.onscroll = function () {
      vm.scrollTop = document.documentElement.scrollTop;
    };
  },
  components: {
    photo,
    rightMenuUpdate,
    generalSituation,
    feedbackComponent,
    stepTable
  },
  methods: {
    choice(i, font) {
      console.log('right menu', i, font);
      this.tabSelected = font;
      this.drawer = true;
      document.getElementsByClassName('scene')[0].style.position = 'inherit';
      document.getElementsByClassName('scene')[0].style.left = '-280px';
    },
    handleClose() {
      return;
    },
    closeModal() {
      this.$refs.drawer.closeDrawer();
      this.drawer = false;
      document.getElementsByClassName('scene')[0].style.position = null;
      document.getElementsByClassName('scene')[0].style.left = 0;
    },
    handleClick(tab, event) {
      console.log('right menu', tab, event);
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-drawer__body {
  padding: 15px 0px !important;
}
.ul-bg{
  border-left: 2px solid #454648;

}
ul {
  position: fixed;
  // top: 146px;
  right: 0;
  margin: 0 auto;
  width: 64px;
  height: calc(100vh - 80px - 104px);
  border-left: 2px solid #D8D8D8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  li {
    // height: 15%;
    min-height: 65px;
    // margin-top: 15px;
    // margin-bottom: 15px;
    // position: relative;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image {
      height: 35px;
      margin-bottom: 6px;
      img {
        height: 34px;
        width: 32px;
      }
    }
  .font-color{
    color: #bababa;
  }
    p {
      // margin-top: 6px;
      // height: 25%;
      // line-height: 25%;
      width: 100%;
      text-align: center;
      color: #333;
    }
    &:hover {
      p {
        color: #1a91ff;
      }
    }
  }
}

.right-menu-drawer {
  z-index: 1 !important;
  position: absolute;
  height: calc(100% - 21px);
  pointer-events: none;
}

.right-menu-drawer {
  /deep/ .el-drawer {
    background:  #F0F8FD;;
    width: 596px;
    box-shadow: none;
    pointer-events: all;
  }
  /deep/ .el-drawer__body {
    padding: 15px;
  }
  /deep/ .el-tabs__item {
    color: #333;
  }
  /deep/ .el-tabs__item.is-active {
    color: #1b91ff;
  }
  /deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
    box-shadow: 0 0 0 0;
  }
  /deep/ .el-tabs__nav-wrap::after {
    background: #F0F8FD;
  }
}
.bg-font{
  /deep/ .el-drawer {
 
  background:  #212124;
}
/deep/ .el-tabs__item {
    color: #bababa;
  }
  /deep/ .el-tabs__nav-wrap::after {
    background: #212025;
  }

}
/deep/ .el-tabs__item {
  // font-size: 16px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  &:nth-child(2) {
    margin-left: 12px;
  }
  &:last-child {
    margin-right: 12px;
  }
}
/deep/ .el-tabs__header {
  margin: 16px 0px 20px;
}
/deep/ .el-tabs__active-bar {
  width: 48px !important;
  height: 4px !important;
  margin-left: 14px !important;
  background: #1b91ff;
}
.icon-style {
  // width: 16px;
  // height: 50px;
  // position: relative;
  float: right;
  // background: #1a91ff;
  // line-height: 50px;
  cursor: pointer;
  z-index: 999;
  position: fixed;
  margin-top: 15px;
  margin-left: -12px;
  // border-radius: 15px 0 0 15px;
}
</style>
<style>
/* @media screen and (max-height: 650px) {
  ul li {
    margin-top: 0px !important;
  }
}
@media screen and (min-height: 650px) and (max-height: 800px) {
  ul li {
    margin-top: 10px !important;
  }
} */
</style>
