<template>
  <div class="file-history-box" v-show="show">
    <el-row style="padding-bottom: 26px">
      <el-col :span="24">
        <span class="go-back" @click="hideEvent">&lt;返回</span>
        <span class="tile">文件记录</span>
      </el-col>
    </el-row>
    <div style="height: calc(100vh - 400px);">
      <el-scrollbar style="height: 100%" ref="scrollFileHistory">
        <el-row class="block-box" v-for="(item, key) in data" :key="key">
          <el-col :span="4" style="width: 20px;">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/File.png`)" />
          </el-col>
          <el-col :span="14" style="width: calc(100% - 162px);padding-left: 12px">
            <el-link :href="item.fileUrl" type="primary" class="file-name">{{ item.fileUrl }}</el-link>
          </el-col>
          <el-col :span="6" style="width: 121px;margin-left: 21px;">
            <span>{{ item.sendDate + ' ' + item.sendTime }}</span>
          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fileHistory',
  data() {
    return {
      fileLogo: require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/文件.png')
    };
  },
  props: {
    show: {
      type: Boolean
    },
    data: {
      type: Object|Array
    }
  },
  watch: {
    show() {
      if (this.show) {
        let vm = this;
        this.$nextTick(() => {
          vm.scrollToBottom();
        });
      }
    }
  },
  methods: {
    hideEvent() {
      this.$emit('update:show', false);
    },
    scrollToBottom() {
      let vm = this;
      setTimeout(() => {
        vm.$refs.scrollFileHistory.wrap.scrollTo({ top: vm.$refs.scrollFileHistory.wrap.scrollHeight });
      }, 100);
    }
  },
  computed: {
    getFileName() {
      return (url) => {
        return url.split('/').pop();
      };
    },
    calcImgUrl() {
      let vm = this;
      return (url) => {
        let name = url.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return url;
        } else {
          return vm.fileLogo;
        }
      };
    },
    calcImgFit() {
      return (url) => {
        let name = url.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return 'fill';
        } else {
          return 'none';
        }
      };
    }
  }
};
</script>
<style lang="less" scoped>
.file-history-box {
  height: 100%;
  padding: 16px 29px 10px 25px;
  .go-back {
    display: block;
    float: left;
    cursor: pointer;
    color: #1a91ff;
  }
  .tile {
    display: block;
    text-align: center;
    cursor: pointer;
    // color: #ffffff;
    // font-family: 'SourceHanSansCN-Light';
  }
  .go-back:hover {
    color: #1a91ff;
  }
  .block-box {
    line-height: 22px;
  }
}
.file-name {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: underline;
}
</style>