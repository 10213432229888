<template>
  <el-dialog
    title="牙齿移动量表"
    :visible.sync="dialogVisible"
    class="hidden-style tooth-movement"
    :class='{"bg":$store.state.model}'
    :modal="modal"
    :close-on-click-modal="clickModal"
  >
    <div class="bolton-box content-border">
      <el-row>
        <span style="margin-right: 6px;">牙弓:</span>
        <el-radio v-model="dentalArch" label="1">上颌</el-radio>
        <el-radio v-model="dentalArch" label="2">下颌</el-radio>
      </el-row>
      <el-row>
        <table
          cellspacing="0"
          v-show="dentalArch === '1'"
          class="table_border table_move"
        >
          <tr>
            <td style="width:250px"></td>
            <td v-for="i in teethUpCodes" :key="i" class="teeth-num">{{ i }}</td>
          </tr>
          <tr v-for="(title, index) in teethTitle" :key="index" class="bottom-border">
            <td class="border-style" style="width:240px;text-align: left;padding-left: 15px">{{ title }}</td>
            <td class="border-style" v-for="(item, num) in teethUpData" :key="num">
              <span :style="setTableCenter(teethUpData[num][index], index)">
                {{ teethUpData[num].length > 0 ? setNum(teethUpData[num][index], index) : setNum('0.0', index) }}
              </span>
            </td>
          </tr>
        </table>

        <table
          cellspacing="0"
          v-show="dentalArch === '2'"
          class="table_border table_move"
        >
          <tr>
            <td style="width:250px"></td>
            <td v-for="i in teethDownCodes" :key="i" class="teeth-num">{{ i }}</td>
          </tr>
          <tr v-for="(title, index) in teethTitle" :key="index" class="bottom-border">
            <td class="border-style" style="width:240px;text-align: left;padding-left: 15px">{{ title }}</td>
            <td class="border-style" v-for="(item, num) in teethDownData" :key="num">
              <span :style="setTableCenter(teethDownData[num][index], index)">
                {{ teethDownData[num].length > 0 ? setNum(teethDownData[num][index], index) : setNum('0.0', index) }}
              </span>
            </td>
          </tr>
        </table>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { GetTeethId, GetMoveData } from '../../../../render/renderES';  // 孙懿修改
import { toDecimal1 } from '@/util/index';
export default {
  name: 'moveWindow',
  data() {
    return {
      dentalArch: '1',
      modal: false, //遮罩层标记
      clickModal: false, //点击空白隐藏标记
      teethTitle: [
        '近中(+)/远中(-) mm',
        '颊向(+)/舌向(-) mm',
        '伸长(+)/压低(-) mm',
        '冠转矩 颊侧(+)/舌侧(-)',
        '冠轴倾 近中(+)/远中(-)',
        '扭转   近中(+)/远中(-)'
      ],
      teethCodesTotal: [],
      teethUpData: [],
      teethDownData: [],
      teethTestData: [],
      teethUpCodes: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28], // 上颌 All
      teethDownCodes: [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38], // 下颌 All
    };
  },
  props: {
    visible: {
      //汇总信息
      type: Boolean
    },
    planIndext: {
      type: Number
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('updateMoveChecked');
        return this.$emit('update:visible', value);
      }
    }
  },
  watch: {
    planIndext() {
      //更新移动量数据
      if (this.visible) {
        this.getTeethData();
      }
    },
    visible() {
      if (this.visible) {
        this.getTeethData();
      }
    }
  },
  methods: {
    setNum(value, index) {
      if (index > 2) {
        return toDecimal1(value) + '°';
      } else {
        return toDecimal1(value) + ' ';
      }
    },
    setTableCenter(value = '0.0', index) {
      value = toDecimal1(value);
      if (index > 2) {
        if (value.indexOf('-') !== -1 || value.indexOf('+') !== -1) {
          return {'padding-left': '0px'};
        } else {
          return {'padding-left': '5px'};
        }
      } else {
        if (value.indexOf('-') !== -1 || value.indexOf('+') !== -1) {
          return {'padding-right': '5px'};
        } else {
          return {'padding-right': '0px'};
        }
      }
      return {};
    },
    getTeethData() {
      // this.teethCodesTotal = GetTeethId();
      // this.teethUpCodes = this.teethCodesTotal.filter(function(code) {
      //   return code < 30;
      // });
      // this.teethDownCodes = this.teethCodesTotal.filter(function(code) {
      //   return code > 30;
      // });
      this.teethUpData = [];
      this.teethDownData = [];
      //上颌移动量
      for (let i = 0; i < this.teethUpCodes.length; i++) {
        this.teethUpData.push(GetMoveData(this.planIndext, this.teethUpCodes[i]));
      }
      //下颌移动量
      for (let j = 0; j < this.teethDownCodes.length; j++) {
        this.teethDownData.push(GetMoveData(this.planIndext, this.teethDownCodes[j]));
      }
    }
  }
};
</script>
<style scoped lang="less">

/deep/ .el-dialog__header {
  background-color: #0488FF;
  padding: 5px 20px;
  border-bottom: 1px solid #454648;
  span {
    color: #FFF;
    font-size: 14px;
  }
  .el-dialog__headerbtn {
    top: 10px;
    i {
      color: #ffffff;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 0px;
}
/deep/ .el-dialog__title {
  line-height: 20px;
}
.bolton-box {
  padding: 13px 39px 7px 20px;
  background: #fff;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  .title {
    padding-bottom: 10px;
  }
}
/deep/ .el-dialog {
  position: fixed;
  bottom: 80px;
  margin-bottom: 0px;
  margin-left: 0px;
  width: calc(100% - 64px);
}
.hidden-style {
  z-index: 0 !important;
}
.border-style {
  border-right: 1px solid #505259;
  // border-top: 1px #a6a6a6 solid;
  padding-top: 2px;
  padding-bottom: 2px;
  width: calc((100% - 240px)/16);
}
.teeth-num {
  padding-top: 4px;
  padding-bottom: 4px;
}
.table_move {
  table-layout:fixed;
  word-break:break-all;
  width: 100%;
  text-align: center;
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table_border tr:nth-child(even){
  background: #E6F1F9;
}
/deep/ .el-radio__label {
  padding-left: 6px;
}
/deep/ .el-radio {
  margin-right: 12px;
  color: #BABABA !important;
}
/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #BABABA !important;
}
.bg{
  /deep/ .el-dialog__header {
    span{
      color:#bababa;
    }
    background-color: #171719;
    
    border-bottom: 1px solid #454648;
 
}
.bolton-box{
  background: #171719;
  color: #bababa;
}
.table_border tr:nth-child(even){
  background: #2e2f32;

}
}
</style>
