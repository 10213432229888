<template>
  <div class="login SXGA-base">
    <pageHeader @reload="reload" />
    <!-- 头部 -->
    <div class="options" :class='{"bg-font":$store.state.model}'>
      <div class="main"  :class='{"border-color":$store.state.model}'>
        <ul class="clearfix">
          <li
            v-for="(item, i) in options"
            :key="i"
            @click="choice.call(this, i, item.visible)"
            @mouseover="item.isHover = true"
            @mouseout="item.isHover = false"
          >
            <div class="image">
              <img :src="item.isHover ? item.urlHover : item.url" v-if='!$store.state.model' />
              <img :src="item.isHover ? item.urlHover1 : item.url1" v-else />
            </div>
            <p>{{ item.font }}</p>
          </li>
          <li style="width: 17px"></li>
          <li
            v-for="(item, i) in otherOptions"
            :key="i + 7"
            :class="item.isCheck ? 'checkBackground' : ''"
            @click="choiceOther.call(this, i)"
            @mouseover="item.isHover = true"
            @mouseout="item.isHover = false"
          >
            <div class="image">
              <img :src="item.isCheck ? item.urlDown : item.isHover ? item.urlHover : item.url" v-if='!$store.state.model' />
              <img :src="item.isCheck ? item.urlDown1 : item.isHover ? item.urlHover1 : item.url1" v-else />
            </div>
            <p>{{ item.font }}</p>
          </li>
        </ul> 
        <div class="dropdown  zt" :class='{border:$store.state.model,dn:!Compatible.isAMKQ}' >
          <span style="margin-right: 5px">切换主题：</span>
          <el-dropdown type="primary" @command="handleCommand1" @visible-change="dropdownHideFunc">
            <el-button type="primary"  class="project-change">
              {{ zt}}
              <i :class="{rX180:dropdownHide}" class="el-icon-caret-bottom"></i>

            </el-button>
            <el-dropdown-menu class="project-dropdown"   slot="dropdown">
              <el-dropdown-item
                v-for="(item, i) in map "
                :key="i"

                :command="{
                  id:item.id,
                  label:item.label    
                }"
                >{{item.label}}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          
        </div>
        <div class="dropdown"  :class='{border:$store.state.model}'>
          <span style="margin-right: 5px">切换方案：</span>
          <el-dropdown type="primary" @command="handleCommand" @visible-change="dropdownHideFunc">
            <el-button type="primary" :disabled="changePlanFlag" class="project-change">
              {{ designate }}
              <i :class="{rX180:dropdownHide}" class="el-icon-caret-bottom"></i>

            </el-button>
            <el-dropdown-menu class="project-dropdown"  slot="dropdown">
              <el-dropdown-item
                 
                v-for="(item, i) in schemeArray"
                :key="i"
                :command="{
                  approvalAndSubmit: item.approvalAndSubmit,
                  planNo: item.planNo,
                  planId: item.planId,
                  pdfUrl: item.pdfUrl,
                  url: item.url,
                  font: `${'方案' + item.planNo}${schemeType[item.status]}`,
                  status: item.status
                }"
                >{{ `${'方案' + item.planNo}${schemeType[item.status]}` }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            type="primary"
            style="
              margin-left: 68px;
              color: #fff;
              background-color: #1a91ff !important;
              background: #1a91ff !important;
              border-color: #1a91ff !important;
              padding: 15px 20px;
              height: 28px;
              line-height: 0px;
              width: 96px;
            "
            :style="(status === 3||quanxian&&authority==2 ||webUrl&&quanxian)? { background: '#9ECCFB !important' } : {}"
            :disabled="status === 3||quanxian&&authority==2||webUrl&&quanxian"
            @click="admitConfirm"
            >批准</el-button
          >
        </div>
      </div>
      <boltonWindow :visible.sync="boltonVisible" @updateBoltonChecked="updateBoltonChecked" />
      <preferenceWindow
        ref="preferenceWindow"
        :visible.sync="preferenceVisible"
        @updatePreferenceChecked="updatePreferenceChecked"
      />
      <planConfirmModal ref="planConfirmModal" :visible.sync="planConfirmVisible" @admitPlan="admitPlan" />
    </div>
    <!-- 中间的选项按钮 -->
    <div class="content" :style="bgColorStyle">
      <canvas class="scene"></canvas>
      <div class="scene-svg" style="position: absolute; z-index: 0; top: 0; width: calc( 100% - 64px ); pointer-events: none"></div>
      <rightMenu :planName="designate" :totalstep.sync="totalstep" :planIndext.sync="planIndext" />
      <switchBtton :value.sync="switchvalue" class="switch-btn" :class="{'font-w':$store.state.model}" />
      <p
        id="loading"
        :class='{"font-color":$store.state.model}'
        style="position: fixed; top: 50%; left: 50%; margin: -50px 0 0 -50px; text-align: center; "
      ></p>

      <moveWindow :visible.sync="moveVisible" :planIndext="planIndext" @updateMoveChecked="updateMoveChecked" />
      <!-- <ul class="clearfix">
        <li @click="retreat">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/后退.png`)" />
        </li>
        <li @click="last">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/上一步.png`)" />
        </li>
        <li @click="autoAdvance">
          <img :src="[
              isPlay
                ? require(`@/assets/#病例详情#3D方案切图9.9/PC/暂停.png`)
                : require(`@/assets/#病例详情#3D方案切图9.9/PC/开始.png`)
            ]" />
        </li>
        <li @click="next">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/下一步.png`)" />
        </li>
        <li @click="advance">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/前进.png`)" />
        </li>
      </ul>-->
    </div>

    <!-- 下面的进度条 -->
    <div class="foot foot-plan" :class='{"foot-bg":$store.state.model}'>
      <div class="plan-btn">
        <ul class="clearfix" style="padding-top: 12px">
          <li @click="retreat">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/First_normal.png`)" v-if='!$store.state.model' />
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/model/First_normal.png`)" v-else />
          </li>
          <li @click="last">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/backward_normal.png`)" v-if='!$store.state.model' />
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/model/backward_normal.png`)"v-else />
          </li>
          <li @click="autoAdvance">
            <img
            v-if='!$store.state.model'
              :src="[
                isPlay
                  ? require(`@/assets/#病例详情#3D方案切图9.9/PC/Pause_hover.png`)
                  : require(`@/assets/#病例详情#3D方案切图9.9/PC/play_normal.png`)
              ]"
            />
            <img
            v-else
              :src="[
                isPlay
                  ? require(`@/assets/#病例详情#3D方案切图9.9/PC/model/Pause_hover.png`)
                  : require(`@/assets/#病例详情#3D方案切图9.9/PC/model/play_normal.png`)
              ]"
            />
          </li>
          <li @click="next">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/forward_normal.png`)"  v-if='!$store.state.model' />
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/model/forward_normal.png`)"  v-else />
          </li>
          <li @click="advance">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/Last_normal.png`)"  v-if='!$store.state.model' />
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/model/Last_normal.png`)"  v-else />
          </li>
        </ul>
      </div>
      <div class="main">
        <ul class="curTop" v-show="panTop" ref="curTop">
          <div
            @mousedown.stop="top()"
            v-show="planIndext == 0"
            class="planOne plan"
            :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`"
          >
            <span v-show="planIndext == 0" class="num">0</span>
          </div>
          <!-- <span class="bottomOne" v-show="!panBottom && panTop">0</span> -->
          <!-- v-for="(item, i) in planArrayT" -->
          <li
            v-for="(item, i) in planArrayT"
            :key="i"
            :class="planIndext > i ? (item.type == 1 ? 'hscur' : 'cur') : ''"
            @click="planClick.call(this, i)"
            :style="{ width: topwidth - 6 + 'px', marginRight: '6px' }"
          >
            <div
              @mousedown.stop="top"
              class="plan"
              v-show="planIndext == i + 1"
              :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')}); left: ${
                (topwidth - 6) / 2 - 12
              }px`"
            >
              <span class="num" v-show="planIndext == i + 1">{{ i + 1 }}</span>
            </div>
            <!-- <span class="bottomIndex" v-if="(i + 1) % 5 === 0" v-show="!panBottom && panTop">{{ i + 1 }}</span> -->
          </li>
          <li class="foot_sz">{{ `${planIndext}/${planArrayT.length}` }}</li>
        </ul>
        <!-- 下进度条 -->
        <ul class="curBottom" v-show="panBottom" ref="curBottom">
          <div
            @mousedown.stop="bottom()"
            v-show="planIndext == 0 && !panTop && panBottom"
            class="planOne plan"
            :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')});`"
          >
            <span v-show="planIndext == 0" class="num">0</span>
          </div>
          <!-- <span class="bottomOne">0</span> -->
          <li
            v-for="(item, i) in planArrayB"
            :key="i"
            :class="planIndext > i ? (item.type == 1 ? 'hscur' : 'cur') : ''"
            @click="planClick.call(this, i)"
            :style="{ width: bottomwidth - 6 + 'px', marginRight: '6px' }"
          >
            <div
              @mousedown.stop="bottom"
              class="plan"
              v-show="planIndext == i + 1 && !panTop && panBottom"
              :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')}); left: ${
                (bottomwidth - 6) / 2 - 12
              }px`"
            >
              <span class="num" v-show="planIndext == i + 1">{{ i + 1 }}</span>
            </div>
            <!-- <span class="bottomIndex" v-if="(i + 1) % 5 === 0">{{ i + 1 }}</span> -->
          </li>
          <li class="foot_sz">{{ `${planIndext}/${planArrayB.length}` }}</li>
        </ul>
      </div>
      <!-- 上进度条 -->

      <!-- <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/未标题-1.png`)" /> -->
    </div>
    <!-- 咬合面lenged -->
    <div class="occlusal_lengend" v-if="occlusalLenged">
      <el-row style="padding: 15px 0px">
        <el-col :span="24" style="display: flex; align-items: center; height: 30px">
          <div class="occlusal_circle" :style="'background-color:' + $store.state.biteColor.split(',')[0]"></div>
          <div style="margin-left: 10px">0~0.3</div>
        </el-col>
        <el-col :span="24" style="display: flex; align-items: center; height: 30px">
          <div class="occlusal_circle" :style="'background-color:' + $store.state.biteColor.split(',')[1]"></div>
          <div style="margin-left: 10px">0.3~0.6</div>
        </el-col>
        <el-col :span="24" style="display: flex; align-items: center; height: 30px">
          <div class="occlusal_circle" :style="'background-color:' + $store.state.biteColor.split(',')[2]"></div>
          <div style="margin-left: 10px">&gt;0.6</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script src="../../render/menuClick.js"></script>
<script>
import { getHashCode } from '@/util/index';

import { getQueryString } from '@/util/validate';

import pageHeader from '@/views/common/zlkj/pageHeader';
import switchBtton from '@/views/common/zlkj/switch';
import boltonWindow from '@/views/common/zlkj/bolton';
import moveWindow from '@/views/common/zlkj/move';
import rightMenu from '@/views/common/zlkj/rightMenu';
import preferenceWindow from '@/views/common/zlkj/preference';
import planConfirmModal from '@/views/common/zlkj/planConfirmModal';

import { render } from '../../render/renderES';

import { initStl } from '../../render/renderES';

import { pointModel } from '../../render/renderES';

import { playModel } from '../../render/renderES';

import menuClickMixin from '../../render/menuClick';
import TopMenu from '../../render/renderTopMenu';
import { GetCamera, GetRenderScene, RemoveRenderScene } from '../../render/renderES';
import { GetShowJaw, shiftAttachVisible, SetAttachmentTransparency } from '../../render/renderES'; // 孙懿修改

let TIMERSTEP = null;
export default {
  mixins: [menuClickMixin, TopMenu],
  data() {
    return {
      zt:'',
      map:[{id:false,label:'浅色主题'},{id:true,label:'深色主题'}],
      authority:1,//1医生 2.员工
      quanxian:true,//批准权限
      webUrl:false,
      dropdownHide:false,
      changePlanFlag: true,
      caseId: 1009515,
      designate: `方案1`,
      overlayMesh: {},
      otherOptions: [
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/contrast_normal.png`), //显示的图片
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/contrast_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/contrast_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/contrast_normal.png`), //显示的图片
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/contrast_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/contrast_down.png`),
          isHover: false,
          font: '叠加', //显示的文字
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Attach_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Attach_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Attach_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Attach_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Attach_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Attach_down.png`),
          isHover: false,
          font: '附件',
          visible: 1,
          isCheck: true //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/number_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/number_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/number_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/number_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/number_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/number_down.png`),
          isHover: false,
          font: '编号',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/IPR_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/IPR_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/IPR_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/IPR_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/IPR_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/IPR_down.png`),
          isHover: false,
          font: 'IPR',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/TMT_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/TMT_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/TMT_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/TMT_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/TMT_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/TMT_down.png`),
          isHover: false,
          font: '移动表',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/OC_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/OC_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/OC_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/OC_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/OC_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/OC_down.png`),
          isHover: false,
          font: '咬合面',
          visible: 1,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Grid_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Grid_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Grid_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Grid_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Grid_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Grid_down.png`),
          isHover: false,
          font: '网格',
          visible: 1,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Arch Width_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Arch Width_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Arch Width_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Arch Width_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Arch Width_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Arch Width_down.png`),
          isHover: false,
          font: '牙弓宽度',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Measure_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Measure_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Measure_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Measure_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Measure_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Measure_down.png`),
          isHover: false,
          font: '测量',
          visible: 2,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Bolton_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Bolton_hover.png`),
          urlDown: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Bolton_down.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Bolton_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Bolton_hover.png`),
          urlDown1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Bolton_down.png`),
          isHover: false,
          font: 'BOLTON',
          visible: 0,
          isCheck: false //是否选中
        }
        // ,
        // {
        //   url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/偏好.png`),
        //   font: '偏好',
        //   visible: 0,
        //   isCheck: false //是否选中
        // }
      ],
      options: [
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/U_normal.png`), //显示的图片
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/U_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/U_normal.png`), //显示的图片
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/U_hover.png`),
          isHover: false,
          font: '上颌', //显示的文字
          visible: 1 //0显示双颌，1显示上颌，2显示下颌
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/L_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/L_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/L_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/L_hover.png`),
          isHover: false,
          font: '下颌',
          visible: 2
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Right_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Right_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Right_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Right_hover.png`),
          isHover: false,
          font: '右侧',
          visible: 0
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Positive_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Positive_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Positive_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Positive_hover.png`),
          isHover: false,
          font: '正面',
          visible: 1
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Left_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Left_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Left_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/Left_hover.png`),
          isHover: false,
          font: '左侧',
          visible: 0
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/L2_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/L2_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/L2_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/L2_hover.png`),
          isHover: false,
          font: '下颌面',
          visible: 2
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/U2_normal.png`),
          urlHover: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/U2_hover.png`),
          url1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/U2_normal.png`),
          urlHover1: require(`@/assets/#病例详情#3D方案切图9.9/PC/model/视角切换图标/U2_hover.png`),
          isHover: false,
          font: '上颌面',
          visible: 1
        }
      ],
      //上进度进度
      planArrayT: [],
      //下进度进度
      planArrayB: [],
      //上进度索引
      planIndext: 0,
      // 上进度是否显示
      panTop: true,
      // 下进度是否显示
      panBottom: true,
      // 是否自动前进
      autoFlag: {
        flag: false,
        interval: null
      },
      // 方案数组集合
      schemeArray: [],
      // 目前方案状态
      status: 1,
      approvalAndSubmit: false,
      schemeType: ['修改中', '需修改', '待确认', '已确认'],
      istop: true,
      topwidth: 0,
      bottomwidth: 0,
      curstep: 0,
      totalstep: [
        [1, 0],
        [2, 0],
        [3, 0],
        [4, 1],
        [5, 1],
        [6, 1],
        [7, 1],
        [8, 1],
        [9, 1],
        [10, 1],
        [11, 1],
        [12, 1]
      ],
      isPlay: false,
      caseUid: '',
      planId: 0,
      token: null,
      btnflag:false,
      switchvalue: 'rotate', //swich按钮value值
      boltonVisible: false, //bolton窗口显示和隐藏
      moveVisible: false, //移动量窗口显示和隐藏
      preferenceVisible: false, //偏好窗口显示和隐藏
      planConfirmVisible: false, //批准方案窗口显示和隐藏
      numberMeshList: {
        up: [],
        bottom: []
      }, //编号mesh对象
      iprMeshLish: [], //切面对象
      occlusalLenged: false //咬合面lenged 显示flag
    };
  },
  async created() {
        this.zt=this.map.filter(item=>{
          if(this.$store.state.model==item.id){
              return  true
          }
        })[0].label
    if(location.href.indexOf('172.168.0.105')!=-1){
      this.webUrl=true;
      this.employee();
    }
    this.authority=localStorage.getItem('authority');
    if(localStorage.getItem('authority')==2) {
      this.employee();
    }
    
    localStorage.setItem('winWidth', '0');
    this.caseId = getQueryString('caseId');
    this.planId = getQueryString('planId') || 0;
    this.token = getQueryString('token') || null;
    // this.caseId = 1009515;
    this.$store.commit('changeCaseId', this.caseId);
    localStorage.setItem('approvalCaseId', this.caseId);
    if (!this.caseId) {
      // this.$router.replace('/index');
    }

    this.plans();

    //设置背景色
    this.$http({
      url: '/plan/getSetting',
      method: 'get'
    }).then(({ data }) => {
      if (data.code == 200) {
        console.log(data)
        data = JSON.parse(data.data);
        this.$store.commit('changeBgColor', data.bgColor);
        this.$store.commit('changeAttachColor', data.attachColor);
        this.$store.commit('changeBiteColor', data.biteColor);
      }
    });
    this.$store.commit('changeSCopyRight', false);

    /** 获取画布尺寸 START */

    // 设置变量
    if (!window.setCanvasAttr) {
      window.setCanvasAttr = () => {
        // 全局画布宽高
        window.canvasAttr = {
          width: window.innerWidth - (64 * window.innerWidth) / 1920,
          height: window.innerHeight - (184 * window.innerWidth) / 1920
        };
        console.log('canvas尺寸', window.canvasAttr);

        /** 高分屏适配 START */
        if (innerWidth > 1920 && this.setZoomStyle) {
          this.setZoomStyle();
        } else {
          // 清除式样
          this.removeZoomStyle();
        }
        /** 高分屏适配 END */
      };
    }
    // 防止重复监听先执行移除事件
    window.removeEventListener('resize', window.setCanvasAttr);
    // 监听窗口重置画布
    window.addEventListener('resize', window.setCanvasAttr);
    // 初始化全局画布变量
    window.setCanvasAttr();

    /** 获取画布尺寸 END */
  },
  beforeMount() {
    if (localStorage.getItem('phone') == '16621097734') {
      this.btnflag = true;
    } else {
      return;
    }

    if (window.location.href.indexOf('easysmile.magicalign') == -1) {
      this.btnflag = true;
    } else {
      this.btnflag = false;
    }
  },
     mounted() { this.$store.commit('changeCopy',true)
    //声明raycaster和mouse变量
    window.raycaster = new THREE.Raycaster();
    window.mouse = new THREE.Vector2();

    /** 高分屏适配 START */
    if (innerWidth > 1920) {
      this.setZoomStyle();
    }
    /** 高分屏适配 END */
  },
  beforeDestroy() {
    // 清除式样
    this.removeZoomStyle();
  },
  components: {
    pageHeader,
    switchBtton,
    boltonWindow,
    rightMenu,
    moveWindow,
    preferenceWindow,
    planConfirmModal
  },
  computed: {
    menuFlag() {
      return {
        planIndext: this.planIndext,
        panTop: this.panTop,
        panBottom: this.panBottom
      };
    },
    bgColorStyle() {
        
      if(this.$store.state.model){
        return { background: 'rgb(46, 47, 51)' };

      }else{
        return { background: this.$store.state.bgColor };

      }
    }
  },
  watch: {
    /**
     * 步骤监听
     */
    menuFlag() {
      this.clearNumberMesh();
      this.numberClick();
      this.clearIprMesh();
      this.iprClick();
      // 牙弓长度
      this.setArch();
      //叠加菜单点击
      this.setContrast();
      //咬合面
      this.setOcclusal();
    }
  },
  methods: {
    handleCommand1(val){
       localStorage.setItem('model',val.id)
       this.zt=val.label
       window.location.reload();
    },
    reload(){
      window.location.reload();
    },
     // 账户权限接口
     employee() {
                this.$http({
                    url: "/staff/rights",
                    method: "get",
                }).then(({
                    data
                }) => {
                    if (data.code == 200) {
                        let employeeData=JSON.parse(data.data)
                       
                        employeeData.filter(item=>{
                            if(item.saleName=='批准3D方案'){
                           this.quanxian=false;
                              
                            }
                        })
                      }


                }).catch(err => {
                    console.log(err)
                })
            },
    dropdownHideFunc(ev){
      this.dropdownHide = ev;
    },
    /** 移除高分屏設置 */
    removeZoomStyle() {
      // 清除式样
      document.getElementById('zoom-style') && document.getElementById('zoom-style').remove();
      document.getElementById('app').style.transform = 'initial';
    },
    /** 高分屏缩放 */
    setZoomStyle() {
      try {
        // 可视高
        var innerHeight = window.innerHeight;
        // 可视宽
        var innerWidth = window.innerWidth;
        // 缩放比
        var zoom = innerWidth / 1920;
        // 缩放判定
        if (zoom > 1) {
          // 清除式样
          this.removeZoomStyle();
          var cHeight = Math.ceil(innerHeight / zoom - 184);

          var app = document.getElementById('app');
          app.style.height = innerHeight / zoom + 'px';
          app.style.transform = 'scale(' + zoom + ')';
          app.style.transformOrigin = '0 0';
          document.querySelector('.login').classList.add('SXGA-style');
          // 创建式样
          var style = document.createElement('style');
          style.id = 'zoom-style';

          style.innerHTML = `

          /* 内容区 */
          body {
            overflow: hidden !important;
          }
          #screenShotContainer{
            transform: scale(${zoom});
            transform-origin: 0 0;
          }
          .SXGA-style{
            height: ${Math.ceil(innerHeight / zoom)}px !important;
          }
          .SXGA-style .scene{
            transform: scale(${1 / zoom});
            transform-origin: 0 0;
          }
          .SXGA-style>.content {
            height: ${cHeight}px !important;
            overflow: hidden;
          }
          .SXGA-style{
            min-height: auto !important;
          }
          .SXGA-style .update-tab{
            height: ${cHeight - 200}px !important;
          }
          .SXGA-style .multimedia-text{
            height: ${cHeight - 250}px !important;
          }
          .SXGA-style .box-card{
            height: ${cHeight - 105}px !important;
          }
          .SXGA-style .box-card .el-card__body{
            height: ${cHeight - 105}px !important;
          }
          .SXGA-style .photo-card{
            height: ${cHeight - 140}px !important;
          }
          .SXGA-style .photo-card .photo-img{
            height: ${cHeight - 200}px !important;
          }
          .SXGA-style .right-menu-drawer .stepTable{
            height: ${cHeight - 105}px !important;
          }
          .SXGA-style .feedback-box .content{
            height: ${cHeight - 355}px !important;
          }
          .SXGA-style .file-history-box>div:nth-child(2){
            height: ${cHeight - 260}px !important;
          }
          .SXGA-style .tooth-movement .el-dialog{
            width: calc(1920px - 64px);
          }
          .SXGA-style .approve-scheme .el-dialog{
            margin-left: calc( 1920px / 2 - 253px ) !important;
            margin-top: calc( 30vh / ${zoom} ) !important;
          }
          .SXGA-style .preference-cls .el-dialog{
            margin-left: calc( 1920px / 2 - 253px ) !important;
            margin-top: calc( 20vh / ${zoom} ) !important;
          }
          .SXGA-style .bolton-analysis .el-dialog{
            margin-top: 200px !important;
            margin-right: calc( 90px + ${innerWidth - 1920}px );
          }
          .SXGA-style .send-screenshot .el-dialog{
            margin-top: 200px !important;
            margin-left: calc( 1920px / 2 - 15% ) !important;
          }
          .SXGA-style #loading{
            left:${1920 / 2}px !important;
          }
          .project-dropdown{
            transform:scale(${zoom});
            margin-left:${-120 / zoom}px;
            transform-origin: 100% top !important;
            width:110px;
            white-space: nowrap !important;
          }
          .el-message{
            transform:scale(${zoom});
            transform-origin: center top;
          }
          `;
          document.body.append(style);
        }
      } catch (e) {
        this.removeZoomStyle();
      }
    },
    //初始化
    init() {
      // this.plans();
      //   异步请求数据,用延时器来代替
      let vm = this;
      setTimeout(() => {
        //控制进度条灰色 1 代表灰色 0代表蓝色
        if (vm.totalstep != undefined && vm.totalstep.length > 0) {
          vm.planArrayT = new Array(vm.totalstep.length - 1);
          vm.planArrayB = new Array(vm.totalstep.length - 1);
          for (var j = 0; j < vm.totalstep.length - 1; j++) {
            vm.planArrayT[j] = {
              type: vm.totalstep[j][0]
            };
            vm.planArrayB[j] = {
              type: vm.totalstep[j][1]
            };
          }
        } else {
          vm.planArrayT = [];
          vm.planArrayB = [];
        }
        vm.planIndext = 0;
        vm.topwidth = vm.$refs.curTop.clientWidth / (vm.planArrayT.length + 1);
        vm.bottomwidth = vm.$refs.curBottom.clientWidth / (vm.planArrayB.length + 1);
      }, 200);
    },
    //方案列表
    plans() {
      this.$http({
        url: '/caseInfo/plans',
        method: 'get',
        params: {
          caseId: this.caseId
        }
      }).then(({ data }) => {
         
         
        
       
        if (data.code == 200) {
          // data.data = data.data.replaceAll('180.166.104.62:5000','localhost:8080')
     
          data = JSON.parse(data.data);
       
          this.designate = `${'方案' + data[0].planNo}${this.schemeType[data[0].status]}`;
          this.schemeArray = data;
           console.log(this.schemeArray[0])
          this.status = this.schemeArray[0].status;
          this.approvalAndSubmit = this.schemeArray[0].approvalAndSubmit;
          this.planId = data[0].planId;
          this.$store.commit('changePlanId', data[0].planId);
       
          this.$store.commit('changePdfUrl', data[0].pdfUrl);
          localStorage.setItem('approvalPlanNo', data[0].planNo);
        
         // console.log(data[0]);

          if (window.location.href.indexOf('easysmile.magicalign') != -1) {
            console.log('正確');
            data[0].url = data[0].url.replace(/case.magicalign/, 'easysmile.magicalign');
           
          } else if (window.location.href.indexOf('vvsmile.magicalign') != -1) {
            console.log('正確');
            data[0].url = data[0].url.replace(/case.magicalign/, 'vvsmile.magicalign');
           
          }else if (window.location.href.indexOf('plusbeauty.magicalign') != -1) {
            console.log('正確');
            data[0].url = data[0].url.replace(/case.magicalign/, 'plusbeauty.magicalign');
           
          }
          else {
           

          
            
            console.log('錯誤的');
          }

          this.getData(data[0].url);
          // this.getData('./MA21073100013.MAF');
        }
      });
    },
    //批准确认框
    admitConfirm() {
      this.$refs.planConfirmModal.planName = this.designate;
      this.planConfirmVisible = !this.planConfirmVisible;
    },
    //确定批准
    admitPlan() {
      let vm = this;
      this.$http({
        url: '/production/approvalPlan',
        method: 'POST',
        data: {
          caseId: localStorage.getItem('approvalCaseId'), //zgs修改
          planNo: localStorage.getItem('approvalPlanNo') //zgs修改
        }
      }).then(({ data }) => {
        if (data.code == 200) {
          this.$message({
            message: '批准成功',
            type: 'success',
            duration: 1500,
            onClose: () => {}
          });
          vm.$http({
            url: '/caseInfo/plans',
            method: 'get',
            params: {
              caseId: vm.caseId
            }
          }).then(({ data }) => {
            if (data.code == 200) {
        console.log(4)
            
              data = JSON.parse(data.data);
              this.schemeArray = data;
            }
          });
          this.status = 3;
          this.designate = '方案' + localStorage.getItem('approvalPlanNo') + '已确认';
        } else {
          this.$message({
            message: data.msg,
            type: 'error',
            duration: 1500,
            onClose: () => {}
          });
        }
      });
      // this.changeURl(status);
    },
    //bolton窗口关闭后取消bolton选中背景色
    updateBoltonChecked() {
      this.otherOptions[9].isCheck = false;
    },
    //移动量窗口关闭后取消选中背景色
    updateMoveChecked() {
      // this.otherOptions[8].isCheck = false;
      this.otherOptions[4].isCheck = false;
    },
    //偏好窗口关闭后取消选中背景色
    updatePreferenceChecked() {
      this.otherOptions[10].isCheck = false;
    },
    choiceOther(i) {
      this.otherOptions[i].isCheck = !this.otherOptions[i].isCheck;
      //点击需要处理的菜单
      // var showListIndex = [11, 12, 13, 14, 15, 16, 17];
      // //点击后，恢复其他项的选中状态
      // if (showListIndex.indexOf(i) !== -1) {
      //   for (let j = 0; j < showListIndex.length; j++) {
      //     if (showListIndex[j] !== i) {
      //       this.options[showListIndex[j]].isCheck = false;
      //     }
      //   }
      // }
      //附件菜单点击
      if (i === 1) {
        this.accessoryClick(this.otherOptions[i].isCheck);
      }
      //编号点击
      if (i === 2) {
        this.numberClick();
      }
      if (i === 3) {
        //IPR
        this.iprClick();
      }
      // 牙弓长度
      this.setArch();
      if (i === 8) {
        window.pointsArray = [];
        window.intersectsArray = [];
        window.window_mouse = false;
        window.allPointsArray = [];
        window.scene.remove(window.moveLine);
        window.allMeshObjectArr.forEach((item) => {
          RemoveRenderScene(item);
        });
        window.allMeshObjectArr = [];
        document.removeEventListener('mousemove', window.onMouseMove, false);
        document.getElementsByClassName('scene')[0].onclick = null;
        window.moveLine = null;
        // if (this.otherOptions[4].isCheck) {
        if (this.otherOptions[8].isCheck) {
          window.menuData = this.otherOptions;
          document.getElementsByClassName('scene')[0].onclick = window.onMouseDown;
          let vm = this;
          document.getElementsByClassName('scene')[0].onmousedown = function (event) {
            vm.mouseButton(event);
          };
        }
      }
      if (i === 6) {
        //网格
        this.setGroupGrid();
      }
      //叠加菜单点击
      if (i === 0) {
        this.setContrast();
        this.clearNumberMesh();
        this.numberClick(); //编号
        this.iprClick(); //ipr
      }
      if (i === 5) {
        //咬合面
        this.setOcclusal();
      }
      //移动量弹窗
      if (i === 4) {
        this.moveVisible = !this.moveVisible;
      }
      //bolton分析弹窗
      if (i === 9) {
        this.boltonVisible = !this.boltonVisible;
      }
      //偏好弹窗
      if (i === 10) {
        this.$refs.preferenceWindow.setPreference();
        this.preferenceVisible = !this.preferenceVisible;
      }
    },
    //调用3d附件的点击事件    参数：true：选中  false：取消选中
    accessoryClick(checkFlag) {
      console.log(checkFlag);
      //3d TODO
      shiftAttachVisible(checkFlag); // 孙懿修改
    },
    // 双颌，单颌，左侧，右侧选项按钮点击函数,参数"i"是点中的那张图,从0开始;
    choice(i, visible) {
      // 孙懿修改
      if (i == 0) {
        this.panTop = true;
        this.panBottom = false;
        this.istop = false;
        if (2 == GetShowJaw() || -1 == GetShowJaw()) {
          pointModel(1);
        }
      } else if (i == 1) {
        this.panTop = false;
        this.panBottom = true;
        this.istop = true;
        if (2 == GetShowJaw()) {
          pointModel(1);
          pointModel(1);
        } else if (1 == GetShowJaw()) {
          pointModel(1);
        }
      } else if (i == 6) {
        //上颌显示
        this.panTop = true;
        this.panBottom = false;
        pointModel(6);
      } else if (i == 5) {
        //下颌显示
        this.panTop = false;
        this.panBottom = true;
        pointModel(5);
      } else if (i == 2) {
        //双颌显示 左
        this.panTop = true;
        this.panBottom = true;
        pointModel(0);
        pointModel(4);
      } else if (i == 3) {
        //双颌显示 正
        this.panTop = true;
        this.panBottom = true;
        pointModel(0);
        pointModel(3);
      } else if (i == 4) {
        //双颌显示 右
        this.panTop = true;
        this.panBottom = true;
        pointModel(0);
        pointModel(2);
      }

      // 网格
      // this.setGridRotatex();
    },
    // 方案1，方案2，方案3，选项按钮点击函数,参数"command"是选中的选项。;
    handleCommand(command) {
      this.designate = command.font;
      this.totalstep = [];
      this.status = command.status;
      this.init();
      if (window.location.href.indexOf('easysmile.magicalign') != -1) {
        console.log('正確');
        command.url = command.url.replace(/case.magicalign/, 'easysmile.magicalign');
        console.log(command.url);
      } else  if (window.location.href.indexOf('vvsmile.magicalign') != -1) {
        console.log('正確');
        command.url = command.url.replace(/case.magicalign/, 'vvsmile.magicalign');
        console.log(command.url);
      }
      this.getData(command.url);
      this.approvalAndSubmit = command.approvalAndSubmit;
      this.planId = command.planId;
      this.$store.commit('changePlanId', command.planId);
      this.$store.commit('changePdfUrl', command.pdfUrl);
      localStorage.setItem('approvalPlanNo', command.planNo);
      this.otherOptions.forEach((item) => {
        item.isCheck = false;
      });
    },
    //上进度条点击
    planClick(i) {
      this.curstep = i + 1;
      this.gotocurstep();
    },
    // 后退
    retreat() {
      this.curstep = 0;
      this.gotocurstep();
    },
    // 上一步
    last() {
      this.curstep--;
      this.gotocurstep();
    },
    // 自动前进
    autoAdvance() {
      this.isPlay = !this.isPlay;
      if (this.isPlay) {
        if (this.curstep == this.totalstep.length - 1) {
          this.curstep = -1;
          TIMERSTEP = setInterval(() => {
            if (this.curstep >= this.totalstep.length - 1) {
              this.isPlay = false;
              this.curstep = this.totalstep.length - 1;
              this.planIndext = this.curstep;
              clearInterval(TIMERSTEP);
              TIMERSTEP = null;
              return;
            }
            this.curstep++;
            this.planIndext = this.curstep;
            playModel(this.curstep);
            shiftAttachVisible(this.otherOptions[1].isCheck);
            SetAttachmentTransparency(this.otherOptions[1].isCheck); // 孙懿修改
            render();
          }, 500);
        } else {
          TIMERSTEP = setInterval(() => {
            if (this.curstep >= this.totalstep.length - 1 || this.curstep < 0) {
              this.isPlay = false;
              this.curstep = this.totalstep.length - 1;
              this.planIndext = this.curstep;
              clearInterval(TIMERSTEP);
              TIMERSTEP = null;
              return;
            }
            this.curstep++;
            this.planIndext = this.curstep;
            playModel(this.curstep);
            shiftAttachVisible(this.otherOptions[1].isCheck);
            SetAttachmentTransparency(this.otherOptions[1].isCheck); // 孙懿修改
            render();
          }, 500);
        }
      } else {
        clearInterval(TIMERSTEP);
        TIMERSTEP = null;
      }
    },
    // 下一步
    next() {
      this.curstep++;
      this.gotocurstep();
    },
    // 前进
    advance() {
      this.curstep = this.totalstep.length - 1;
      this.gotocurstep();
    },
    async getData(url) {
      // console.trace();
       
      let loadingdom = document.getElementById('loading');
      loadingdom.innerText = '方案加载中……';
      this.changePlanFlag = true;
      this.caseUid = getHashCode(url).toString(36);
      // console.error(this.caseUid, initStl);
      initStl(this.caseUid, url, false);

      TIMERSTEP = setInterval(() => {
       
        if (localStorage.getItem(this.caseUid) == null) {
          console.log('未拿到数据');
        } else {
          loadingdom.innerText = '';
          this.changePlanFlag = false;
          this.totalstep = JSON.parse(localStorage.getItem(this.caseUid));
          localStorage.removeItem(this.caseUid);
          clearInterval(TIMERSTEP);
          TIMERSTEP = null;
          this.init();
          window.camera = GetCamera();
          window.GetCamera = GetCamera;
          window.scene = GetRenderScene();
          this.initTopMenu();
          this.panTop = true;
          this.panBottom = true;
        }
      },100);
    },
    gotocurstep() {
      if (this.curstep < 0) {
        this.curstep = 0;
      } else if (this.curstep >= this.totalstep.length) {
        this.curstep = this.totalstep.length - 1;
      }
      this.planIndext = this.curstep;
      playModel(this.curstep);
      shiftAttachVisible(this.otherOptions[1].isCheck);
      SetAttachmentTransparency(this.otherOptions[1].isCheck); // 孙懿修改
    },
    changeURl(status) {
      // if(status === 3) return;
      if (this.approvalAndSubmit) {
        this.$message({
          message: '方案已经批准并下订单，不能再次批准',
          type: 'success'
        });
        return;
      }

      this.LoadingOpen();
      this.$http({
        url: '/production/approvalPlan',
        method: 'POST',
        data: {
          caseId: localStorage.getItem('approvalCaseId'), //病例ID
          planNo: localStorage.getItem('approvalPlanNo')
        }
      })
        .then(({ data }) => {
          this.LoadingClose();
          if (data.code == 200) {
            if (status != 3) {
              this.$message({
                message: '批准成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            }
            this.$router.push('./hoxuxinjian/approvalPlan');
            this.$store.commit('indexAdd', ['新建订单', '/hoxuxinjian/approvalPlan']);
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit('refreshDataList');
              }
            });
          }
          this.httpflag = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    top() {
      document.onmousemove = this.moveTop;
      document.addEventListener('mouseup', (e) => {
        document.onmousemove = null;
      });
    },
    bottom() {
      document.onmousemove = this.moveBottom;
      document.addEventListener('mouseup', (e) => {
        document.onmousemove = null;
      });
    },
    moveTop(e) {
      let num = Math.floor((e.x - this.$refs.curTop.getBoundingClientRect().left) / this.topwidth);
      this.planIndext = num >= this.planArrayT.length ? this.planArrayT.length : num;
      this.planClick(this.planIndext);
    },
    moveBottom(e) {
      let num = Math.floor((e.x - this.$refs.curBottom.getBoundingClientRect().left) / this.topwidth);
      this.planIndext = num >= this.planArrayT.length ? this.planArrayT.length : num;
      this.planClick(this.planIndext);
    }
  }
};
</script>
<style scoped lang="less">
.border{
  border:0px solid #0488FF!important;
}
/deep/ .el-radio-button__inner {
  background: none;
  color: #ddd;
  height: 32px;
  padding: 8px 20px;
}
.login {
  height: 100vh;
  font-size: 14px;
  min-height: calc(100vh - 77px);
  overflow: hidden;
  position: relative;
  background: #dfe0e2;

  /deep/.dlbgimg {
    height: 100%;
    display: block;
    margin-top: -80px;
  }

  .topdhlg {
    width: 100%;
    height: 80px;
    background: #353b43;
    position: relative;
    box-shadow: 0px 5px 20px #353b43;
  }
}
.bg-font{

  background: #171719 !important;
   
    color: #bababa !important;;
    ul li p{
    color: #bababa !important;;

    }
}
.options {
  height: 64px;
  width: 100%;
  margin-top: 40px;
  background: #FFF;
  // border-top: 2px solid #2c2d31;
  font-size: 12px;
  color: #333;
  .main {
    width: 100%;
    position: relative;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/ .el-row {
      width: 70%;
      margin: 0 auto;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .lgimg {
      display: block;
      width: 123px;
      position: absolute;
      z-index: 99;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    ul {
      height: 100%;
      width: 80%;
      li {
        float: left;
        width: 58px;
        height: 58px;
        position: relative;
        margin-right: 14px;
        margin-top: 3px;
        // font-size: 14px;
        // border-right: #424243 1px solid;

        .image {
          width: 100%;
          height: 36px;

          position: relative;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:hover {
          cursor: pointer;
          color: #1a91ff;
        }

        &:first-child {
          margin-left: 11px;
        }
        &:last-child {
          margin-right: 0;
          border-right: 0px;
        }

        p {
          width: 100%;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
font-weight: normal;
color: #333333;
        }
      }
      .checkBackground:hover {
        color: white;
      }
    }
//
    /deep/.dropdown {
      width: 20%;
      min-width: 380px;
      text-align: right;
      padding-right: 54px;
      .el-button-group {
        height: 35px;
      }
      .el-dropdown {
        border:1px solid #0488FF!important;
        border-radius:4px;
        .el-button--primary {
          display: flex;
          justify-content: space-around;
          font-size: 12px;

          span {
            width: 100%;
            .el-icon-caret-bottom {
              float: right;
            }
          }
        }
      }
      .el-button--primary {
        height: 28px;
        width: 120px;
        padding: 8px;
        background-color: white !important;
        background: white !important;
        border: none !important;
        color: #1a91ff;
        font-size: 12px;
      }

      .el-dropdown__caret-button {
        width: 25px;
        padding: 10px 5px 12px 0px;
      }
    }
    .border{
    
      /deep/.el-dropdown {
        border:0px solid #0488FF!important;  

  
}
}
.zt {
  min-width:auto;
  width:220px;
  /deep/ .el-button--primary {
   width:90px;
}
}
  }
}
.checkBackground {
  color: white;
  background-color: #1b91ff;
  border-radius: 4px;
  &:hover {
    color: white;
  }
}

.content {
  height: calc(100vh - 146px);
  min-height: 390px;
  > img {
    display: block;
    margin: 0 auto;
  }

  position: relative;

  ul {
    width: 380px;
    position: fixed;
    bottom: 110px;
    left: 50%;
    margin-left: -190px;

    li {
      float: left;
      margin: 0 20px;
    }
  }
}

.foot {
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  background: #f9f9f9;
  border-top: 2px solid #D8D8D8;
  width: 100%;
  // height: 9%;
  min-height: 80px;
  box-sizing: border-box;
  z-index: 1;

  .hide {
    display: none;
  }
.border-color{
 ul li{
  border-color:#5a94c7 !important;
 }
}
  .main ul {
    // width: 90%;
    margin-top: 10px;
    // height: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;

    .plan {
      position: absolute;
      height: 30px;
      width: auto;
      top: -32px;
      // right: -12.44px;
      // transition: all 0.1s linear;
      width: 24px;
      height: 30px;
      background-size: cover;
      cursor: pointer;
    }

    .planOne {
      left: -12.44px;
    }

    .num {
      position: absolute;
      // top: -30px;
      width: 100%;
      text-align: center;
      color: #ffffff;
      user-select: none;
      // transition: all 0.1s linear;
      padding-top: 3px;
    }

    .bottomOne {
      position: absolute;
      bottom: -30px;
      left: 0;
      color: #000;
    }

    .bottomIndex {
      position: absolute;
      bottom: -30px;
      left: 50%;
      color: #000;
      transform: translateX(-50%);
    }

    li {
      width: 50px;
      height: 6px;
      // border: 1px solid #1175d2;
      border: 1px solid #0488FF;
      border-radius: 2px;
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
    }

    .foot_sz {
      position: absolute;
      right: -55px;
      top: -5px;
      width: 40px;
      border: none;
      font-size: 14px;
      color: #7bb2e1;
    }

    .cur {
      background: #1c91ff;
      border: 1px solid #1c91ff;
      // transition: all 0.1s linear;
    }

    .hscur {
      background: #1c91ff;
      border: 1px solid #1c91ff;
    }
  }

  img {
    width: 100%;
  }
}
.foot-bg{
  background: #2e2f33 !important;
  border-top: 2px solid #454648  !important;
   
}
.foot-plan {
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .plan-btn {
    ul {
      // width: 300px;
      padding-left: 20px;
      padding-right: 40px;
      li {
        float: left;
        margin: 0 12px;
      }
    }
  }
  .main {
    width: calc(100% - 380px);
    margin-left: 0;
  }
}

.switch-btn {
  position: fixed;
  z-index: 10000;
  top: 124px;
  left: 32px;
  /deep/ .el-radio-button__inner {
    font-size: 12px;
    color:#000;
    border:1px solid #409EFF;
  }
  /deep/ .is-active .el-radio-button__inner {
     color:#fff;
  }
}
.font-w{
  /deep/ .el-radio-button__inner {
    font-size: 12px;
    color:#FFF;
    border:1px solid #409EFF;
  }
}
.occlusal_lengend {
  position: fixed;
  bottom: 100px;
  left: 0px;
  width: 110px;
  height: 120px;
  background-color: #ffffff;
  border-radius: 4px;
}
.occlusal_circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 15px;
}
.project-dropdown {
   
  max-height: 360px;
  overflow: auto;

  /deep/ .el-dropdown-menu__item {
    font-size: 12px;
  }
}
/deep/ .el-button{
        border:1px solid !important;
    }

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3x;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
.font-color{
  color:#FFF;
}
</style>
<style lang="less">

@font-face {
  font-family: 'Alibaba-PuHuiTi-R';
  src: url('../../assets/fonts/Alibaba-PuHuiTi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html,
body,
.login {
  font-family: 'Alibaba-PuHuiTi-R' !important;
}
#app {
  min-width: 1900px;
}

/* 去除焦点线 */
.el-drawer__container:focus-visible {
  outline: none;
}

.el-drawer__container:focus {
  outline: none;
}

.el-drawer__container .el-drawer {
  outline: none;
}

.el-drawer__container .el-drawer:focus {
  outline: none;
}

.main:focus {
  outline: none;
}

.header-style:focus {
  outline: none;
}

/* 禁止涂抹选中 */
.SXGA-style .main,
.SXGA-style .foot,
.SXGA-style .clearfix {
  user-select: none;
}

/* 高分屏 */
.SXGA-style {
  max-width: 1920px;
  position: relative;
}

.SXGA-style .header-style {
  position: absolute !important;
}

.SXGA-style .content ul.clearfix {
  position: absolute;
  top: 0 !important;
  height: 100% !important;
}

.SXGA-style .right-menu-drawer {
  height: 100% !important;
}

.SXGA-style .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #1b91ff;

}

.SXGA-base div:focus {
  outline: none !important;
}

.SXGA-base span:focus {
  outline: none !important;
}

.rX180{
  transform: rotateZ(180deg);
}

.SXGA-base .switch-btn *:focus {
  outline: none !important;
}
.dn{
     display:none !important;
}
</style>