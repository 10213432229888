<template>
  <el-row class="chat-box">
    <el-col :span="24" class="object-box">
      <div style="margin-right: 11px">
        <div style="text-align: right">
          <span style="font-size: 12px;color: #BABABA">{{ sendTime }}</span>
        </div>
        <div class="" v-show="type === 1">
          <span class="float-right text-content-box">{{ content }}</span>
        </div>
        <div v-show="type === 2 && fileType === 'file'">
          <div class="file-box">
            <!-- 文件描述div -->
            <div style="width: 150px" class="float-left">
              <div>
                <p style="height: 60px; word-wrap: break-word; overflow-y: hidden" :title="dealFileName(fileUrl)">
                  {{ dealFileName(fileUrl) }}
                </p>
              </div>
              <div>
                <div class="float-left">
                  <span>{{ size }}</span>
                </div>
                <div class="float-right"><span class="download" @click="getFile(fileUrl)">下载</span></div>
              </div>
            </div>
            <!-- img表示div -->
            <div class="float-right">
              <el-image style="width: 70px; height: 80px" :src="calcImgUrl(fileUrl)" :fit="calcImgFit(fileUrl)" />
            </div>
          </div>
        </div>
        <div v-show="type === 2 && fileType === 'img'">
          <el-image style="width: 150px;" :src="fileUrl" fit="contain" />
        </div>
      </div>
      <div>
        <el-avatar :src="doctorLogo"></el-avatar>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'userChatBox',
  props: {
    type: {
      type: Number
    },
    content: String,
    sendTime: String,
    fileType: String,
    fileUrl: String,
    size: String
  },
  data() {
    return {
      doctorLogo: require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png'),
      fileLogo: require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/文件1.png')
    };
  },
  computed: {
    dealFileName() {
      return (url) => {
        // 获取文件blob数据并保存
        var num = url.lastIndexOf('/') + 1;
        //把参数和文件名分割开
        return url.substring(num).split('?')[0];
      };
    },
    calcImgUrl() {
      let vm = this;
      return (url) => {
        let name = url.split('?')[0].split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return url;
        } else {
          return vm.fileLogo;
        }
      };
    },
    calcImgFit() {
      return (url) => {
        let name = url.split('?')[0].split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return 'fill';
        } else {
          return 'none';
        }
      };
    }
  },
  methods: {
    download(link, name) {
      let eleLink = document.createElement('a');
      eleLink.download = name;
      eleLink.style.display = 'none';
      eleLink.href = link;
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    },
    getFile(link) {
      console.log(link);
      if (!link) {
        return;
      }
      var num =  link.lastIndexOf('/') + 1;
      var name = link.substring(num).split('?')[0];
      let vm = this;
      this.$http({
        url: link,
        responseType: 'blob'
      })
        .then((response) => {
          const link = URL.createObjectURL(response.data);
          vm.download(link, name);
        })
        .catch(() => {
          this.$message.error('下载失败');
        });
    },
    getFilea(url) {
      console.log(url);
      if (!url) return;
      url = url.replace(/\\/g, '/');
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          // 获取文件blob数据并保存
          var num = url.lastIndexOf('/') + 1;
          //把参数和文件名分割开
          var fileName = url.substring(num).split('?')[0];
          var export_blob = new Blob([xhr.response]);
          var save_link = document.createElement('a');
          save_link.href = URL.createObjectURL(export_blob);
          save_link.download = fileName;
          save_link.click();
        }
      };
      this.loading = true;
      xhr.send();
    }
  }
};
</script>
<style lang="less" scoped>
.chat-box {
  float: right;
  margin-bottom: 14px;

  .object-box {
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;

    .file-box {
      width: 248px;
      height: 84px;
      background-color: #2f3035;
      padding: 16px;

      span {
        font-size: 14px;
      }

      .download {
        color: #1a91ff;
        cursor: pointer;
      }
    }
  }
  .text-content-box {
    background-color: #0488FF;
    padding: 8px 12px;
    margin-left: 40px;
    border-radius: 5px;
    display: inline-block;
    // width: 350px;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 14px;
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
/deep/ .el-avatar{
  width: 32px;
  height: 32px;
}
</style>