<template>
  <el-dialog
    title="偏好"
    :visible.sync="dialogVisible"
    width="506px"
    top="20vh"
    class="preference-cls"
    :modal="modal"
    :close-on-click-modal="clickModal"
  >
    <div class="bolton-box">
      <el-row class="title">
        <el-col :span="24"><span>颜色设置</span></el-col>
      </el-row>
      <el-row class="title">
        <el-col :span="12">
          <span>背景色:</span>
          <el-dropdown trigger="click">
            <el-button type="normal"
              ><div class="color-style" :style="bgCheckedColor"></div>
              <i class="el-icon-caret-bottom icon-style"></i>
            </el-button>
            <el-dropdown-menu class="dropmenu-style" slot="dropdown">
              <div style="width: 220px">
                <el-dropdown-item
                  v-for="(color, index) in colorList"
                  :key="index"
                  class="color-card"
                  :style="{ backgroundColor: color }"
                  @click.native="changeBgColor(color)"
                ></el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <!-- <el-col :span="12">
          <span>附件色:</span>
          <el-dropdown trigger="click">
            <el-button type="normal"
              ><div class="color-style" :style="attachCheckedColor"></div>
              <i class="el-icon-caret-bottom icon-style"></i>
            </el-button>
            <el-dropdown-menu class="dropmenu-style" slot="dropdown">
              <div style="width: 220px">
                <el-dropdown-item
                  v-for="(color, index) in colorList"
                  :key="index"
                  class="color-card"
                  :style="{ backgroundColor: color }"
                  @click.native="changeAttachColor(color)"
                ></el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col> -->
        <el-col :span="12">
          <span>咬合色:</span>
          <el-dropdown trigger="click">
            <el-button type="normal"
              ><div class="bitecolor-style" :style="{ backgroundColor: biteColor.split(',')[0] }"></div>
              <div class="bitecolor-style" :style="{ backgroundColor: biteColor.split(',')[1] }"></div>
              <div
                class="bitecolor-style"
                style="margin-right:20px"
                :style="{ backgroundColor: biteColor.split(',')[2] }"
              ></div>
              <i class="el-icon-caret-bottom icon-style"></i>
            </el-button>
            <el-dropdown-menu class="dropmenu-style" slot="dropdown">
              <div style="width: 205px">
                <el-dropdown-item @click.native="changeBiteColor(biteColorList[0])">
                  <div
                    :style="{ backgroundColor: biteColorList[0].split(',')[0] }"
                    style="background:red;width: 24px;height: 24px;float:left"
                  ></div>
                  <div
                    :style="{ backgroundColor: biteColorList[0].split(',')[1] }"
                    style="background:red;width: 24px;height: 24px;float:left"
                  ></div>
                  <div
                    :style="{ backgroundColor: biteColorList[0].split(',')[2] }"
                    style="background:red;width: 24px;height: 24px;float:left;margin-right: 20px;margin-bottom: 10px"
                  ></div>
                </el-dropdown-item>
                <el-dropdown-item @click.native="changeBiteColor(biteColorList[1])">
                  <div
                    :style="{ backgroundColor: biteColorList[1].split(',')[0] }"
                    style="background:red;width: 24px;height: 24px;float:left"
                  ></div>
                  <div
                    :style="{ backgroundColor: biteColorList[1].split(',')[1] }"
                    style="background:red;width: 24px;height: 24px;float:left"
                  ></div>
                  <div
                    :style="{ backgroundColor: biteColorList[1].split(',')[2] }"
                    style="background:red;width: 24px;height: 24px;float:left;margin-bottom: 10px"
                  ></div>
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
     <!-- <el-row class="title" style="margin-top: 10px">
        <el-col :span="12">
          <span>咬合色:</span>
          <el-dropdown trigger="click">
            <el-button type="normal"
              ><div class="color-style" :style="biteCheckedColor"></div>
              <i class="el-icon-caret-bottom icon-style"></i>
            </el-button>
            <el-dropdown-menu class="dropmenu-style" slot="dropdown">
              <div style="width: 220px">
                <el-dropdown-item
                  v-for="(color, index) in colorList"
                  :key="index"
                  class="color-card"
                  :style="{ backgroundColor: color }"
                  @click.native="changeBiteColor(color)"
                ></el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>  -->
     <!-- <el-row class="title" style="margin-top: 35px">
        <el-col :span="24"><span>界面设置</span></el-col>
      </el-row>
      <el-row class="title">
        <el-col :span="24">
          <span style="margin-right: 30px">旋转模式:</span>
          <el-radio v-model="rotationType" label="1">简洁</el-radio>
          <el-radio v-model="rotationType" label="2">精确</el-radio>
        </el-col>
      </el-row>
      <el-row class="title">
        <el-col :span="24">
          <span style="margin-right: 30px">患者信息:</span>
          <el-radio v-model="patientInfo" label="1">显示</el-radio>
          <el-radio v-model="patientInfo" label="2">不显示</el-radio>
        </el-col>
      </el-row>
      <el-row class="title">
        <el-col :span="24">
          <el-checkbox v-model="biteValue">牙齿移动时包含咬合矫正数值</el-checkbox>
        </el-col>
      </el-row>-->
      <el-row style="width: 64%;margin-top: 98px;margin-left: 68px;">
        <el-col :span="12" style="text-align: center">
          <el-button type="primary" class="button-style" @click="save">保存</el-button>
        </el-col>
        <el-col :span="12" style="text-align: center">
          <el-button class="button-style" @click="cancel">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'preferenceWindow',
  data() {
    return {
      modal: false, //遮罩层标记
      clickModal: false, //点击空白隐藏标记
      rotationType: '2',
      patientInfo: '1',
      biteValue: false,
      bgColor: '#DFE0E2',
      attachColor: '#DFE0E2',
      biteColor: '#99E602,#FF6600,#A60100',
      biteColorList: ['#99E602,#FF6600,#A60100', '#006CFF,#F2DC00,#786B21'],
      colorList: [
        '#DFE0E2',
        '#000000',
        '#FFFFFF',
        '#EAF7FF',
        '#666259',
        '#251E18',
        '#E76F70',
        '#EBDECD',
        '#E69770',
        '#D3E76E',
        '#6FE7E6',
        '#6FABE7'
      ]
    };
  },
  props: {
    visible: {
      type: Boolean
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('updatePreferenceChecked');
        return this.$emit('update:visible', value);
      }
    },
    bgCheckedColor() {
      return { backgroundColor: this.bgColor };
    },
    attachCheckedColor() {
      return { backgroundColor: this.attachColor };
    },
    biteCheckedColor() {
      return { backgroundColor: this.biteColor };
    }
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
      this.$emit('updatePreferenceChecked');
    },
    changeBgColor(color) {
      this.bgColor = color;
    },
    changeAttachColor(color) {
      this.attachColor = color;
    },
    changeBiteColor(color) {
      this.biteColor = color;
    },
    //保存偏好设置
    save() {
      this.$http({
        url: '/plan/setting',
        method: 'POST',
        data: {
          bgColor: this.bgColor,
          attachColor: this.attachColor,
          biteColor: this.biteColor,
          rotationType: this.rotationType,
          patientInfo: this.patientInfo,
          biteValue: this.biteValue
        }
      }).then(({ data }) => {
        if (data.code == 200) {
          this.$store.commit('changeBgColor', this.bgColor);
          this.$store.commit('changeAttachColor', this.attachColor);
          this.$store.commit('changeBiteColor', this.biteColor);
          this.$emit('update:visible', false);
          this.$emit('updatePreferenceChecked');
        }
      });
    },
    //保存偏好设置
    setPreference() {
      this.$http({
        url: '/plan/getSetting',
        method: 'get'
      }).then(({ data }) => {
        if (data.code == 200) {
          data = JSON.parse(data.data);
          this.bgColor = data.bgColor;
          this.attachColor = data.attachColor;
          this.biteColor = data.biteColor;
          this.rotationType = data.rotationType.toString();
          this.patientInfo = data.patientInfo.toString();
          this.biteValue = data.biteValue;
        }
      });
    },
    /**
     * 获取bolton数据
     */
    featchData() {
      //TO DO
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-dialog__header {
  background-color: #171719;
  padding: 5px 20px;
  span {
    color: #ffffff;
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 10px;
    i {
      color: #ffffff;
    }
  }
}
/deep/ .el-dialog__title {
  line-height: 30px;
}
/deep/ .el-dialog__headerbtn {
  font-size: 20px;
}
/deep/ .el-dialog__body {
  padding: 0px 0px 20px;
  font-size: 16px;
}
/deep/ .el-radio__label {
  font-size: 16px;
  color: #333;
}
/deep/ .el-radio__inner {
  width: 16px;
  height: 16px;
}
/deep/ .el-checkbox__label {
  font-size: 16px;
  color: #333;
}
/deep/ .el-checkbox__inner {
  width: 18px;
  height: 18px;
}
/deep/ .el-checkbox__inner::after {
  height: 10px;
  left: 6px;
}
/deep/ .el-button {
  padding: 3px 10px;
  margin-left: 13px;
  margin-top: -8px;
  padding-right: 24px;
}
/deep/ .el-button--primary {
  background-color: #1a91ff !important;
  background: #1a91ff !important;
  border-color: #1a91ff !important;
}
.dropmenu-style {
  background: #efefef;
  border: 1px solid #c6e2ff;
  margin-top: 0px;
  padding: 15px 0px 2px;
}
.bolton-box {
  color: #333;
  padding: 28px 0px 10px 24px;
  .title {
    padding-bottom: 25px;
  }
}
.button-style {
  padding: 7px 10px;
  width: 88px;
  font-size: 16px;
  height: 28px;
}
.color-style {
  width: 92px;
  margin-right: 20px;
  height: 30px;
  display: inline-block;
}
.bitecolor-style {
  width: 30px;
  height: 30px;
  display: inline-block;
}
.icon-style {
  position: absolute;
  margin-top: 6px;
  font-size: 20px;
}
.color-card {
  width: 24px;
  height: 24px;
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0px;
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
