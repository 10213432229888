// v-dialogDrag: 弹窗拖拽
import Vue from 'vue';
Vue.directive('dialogDrag', {
  bind(el, binding, vnode, oldVnode) {
    let dialogHeaderEl = el.querySelector('.el-dialog__header');
    dialogHeaderEl.style.cursor = 'move';
    let dragpoint = {};
    const mousemove = ev => {
      let _dragdom = el.querySelector('.el-dialog');
      let sty = window.getComputedStyle(_dragdom, null);
      _dragdom.style.marginLeft = `${parseFloat(sty.marginLeft) + ev.clientX - dragpoint.x}px`;
      _dragdom.style.marginTop = `${parseFloat(sty.marginTop) + ev.clientY - dragpoint.y}px`;
      dragpoint = {
        x: ev.clientX,
        y: ev.clientY
      };
    };
    dialogHeaderEl.addEventListener('mousedown', ev => {
      let target = ev.target;
      //由于点击关闭按钮会事件冒泡，取消拖拽
      if (target.classList.contains('el-dialog__close')) {
        return;
      }
      dragpoint = {
        x: ev.clientX,
        y: ev.clientY
      };
      document.addEventListener('mousemove', mousemove, false);
    });
    document.addEventListener('mouseup', ev => {
      document.removeEventListener('mousemove', mousemove, false);
    });
  }
});
