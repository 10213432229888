<template>
  <textarea style="resize: none" rows="6" v-model="textAreaContent" class="input-box-in" />
</template>
<script>
export default {
  name: 'areatextComponent',
  props: {
    value: {
      type: String
    }
  },
  computed: {
    textAreaContent: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.input-box-in {
  font-family: 'SourceHanSans-CN-Regular' !important;
  padding-left: 25px;
  box-sizing: border-box;
  padding-right: 15px;

  font-size: 14px;
  color: #aaadb1 !important;
  line-height: 19px;
  margin-bottom:5px ;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  margin-right: 1px;
  cursor: default;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.1);
}
</style>
