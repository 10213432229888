<template>
  <el-header class="header-style" :class='{"bg-font":$store.state.model}' height="40px">
    
    <img class="lgimg" :class="{lgImg1:(Compatible.isJYYX||Compatible.isJge||Compatible.isAMKQ)}"  :src="Compatible.loginImage3D" v-if="Compatible.loginImage3D" />
    
    <img class="lgimg" :src="Compatible.loginImage" v-else />
    <div style="margin-right:22px">
      <span>患者：{{ patientName }}</span>
      <span style="margin-left: 71px" v-if="!Compatible.isJYYX&&!Compatible.ismysj" >病例编号：{{ outerNo }}</span>
    
    </div>
  </el-header>
</template>
<script>
import { getQueryString } from '@/util/validate';
import Compatible from '../../../../util/compatible';
export default {
  name: 'pageHeader',
  data() {
    return {
      caseId: '',
      planId: 0,
      token: null,
      patientName: '',
      outerNo: ''
    };
  },
  async created() {
    this.caseId = getQueryString('caseId');
    this.planId = getQueryString('planId') || 0;
    this.token = getQueryString('token') || null;
  },
  async    mounted() { this.$store.commit('changeCopy',true)
    await this.getPatient();
    if (window.location.href.indexOf('easysmile.magicalign.com') != -1) {
      console.warn(document.querySelectorAll('.faviconICON')[0]);
      document.querySelectorAll('.faviconICON')[0].href = require('@/assets/favicon.png');
      document.querySelectorAll('title')[0].innerText = `${this.patientName}- 患者详情`;
    }
  },
  components: {},
  methods: {
    changeModel(){
        
      localStorage.setItem('model',localStorage.getItem('model')=='true'?false:true)
          this.$emit('reload');
      
    },
    //获取患者信息
    getPatient() {
      return this.$http({
        url: '/plan/patient',
        method: 'POST',
        data: {
          caseId: this.caseId
        }
      }).then(({ data }) => {
        if (data.code == 200) {
          data = JSON.parse(data.data);
          this.patientName = data.patientName;
          this.outerNo = data.outerNo;
        }
      });
    }
  }
};
</script>
<style scoped>
.bg-font{
  background-color: #171719 !important;
 color: #bababa !important;
 border-bottom-color:#454648 !important;

}
.header-style {
  background-color: #2281D6;
 
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #2281D6;
  font-size: 14px;
font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
font-weight: normal;
color: #FFFFFF;
}
.lgImg1{
  width: 128px;
}
/* .lgimg {
  display: block;
  width: 123px;
  height: 20px; 
} */
</style>
