<template>
  <div class="feedback-box" :class="{'bg-color':$store.state.model}">
    <el-row>
      <el-col :span="24" class="title-box" style="padding-bottom: 10px">
        <img :src="Compatible.companyLogo" alt="logo" />
        <span class="company-title">{{Compatible.title}}</span>
      </el-col>
    </el-row>
    <el-row style="height: calc(100vh - 530px)" class="content" v-show="fileHistory === false">
      <el-scrollbar style="height: 100%" ref="scroll">
        <!-- 聊天记录框 -->
        <el-col :span="24" v-for="(item, index) in messageList" :key="index">
          <el-row v-show="calcShowFlag(item.sendDate)">
            <el-col :span="24" class="date-box">
              <span>{{ item.sendDate }}</span>
            </el-col>
          </el-row>
          <!-- 客服回复框 -->
          <companyChatBox
            v-if="item.sendType === 2"
            :content="item.msg"
            :time="item.sendTime"
            :type="item.msgType"
            :fileType="item.fileType"
            :fileUrl="item.fileUrl"
            :size="item.fileSize"
            style="padding-right: 22px"
          />
          <!-- 医生发言框 -->
          <user-chat-box
            v-if="item.sendType === 1"
            :type="item.msgType"
            :content="item.msg"
            :sendTime="item.sendTime"
            :fileType="item.fileType"
            :fileUrl="item.fileUrl"
            :size="item.fileSize"
            style="padding-right: 22px"
          />
        </el-col>
      </el-scrollbar>
    </el-row>
    <!-- 工具框、输入框 -->
    <inputComponent ref="fileHistory" :show.sync="fileHistory" @sendMessage="sendMessage" @sendFile="sendFile" />
    <!-- 文件记录框 -->
    <fileHistory :show.sync="fileHistory" :data="fileList" />
  </div>
</template>
<script>
import companyChatBox from './companyChatBox.vue';
import userChatBox from './userChatBox.vue';
import fileHistory from './fileHistory.vue';
import inputComponent from './inputComponent.vue';

export default {
  name: 'feddbackComponent',
  components: {
    companyChatBox,
    userChatBox,
    fileHistory,
    inputComponent
  },
  data() {
    return {
      // companyLogo: require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像2.png'),
      setTime: 20000,
      fileHistory: false,
      messageList: [],
      fileList: {}
    };
  },
  computed: {
    calcShowFlag() {
      // let vm = this;
      return (value) => {
        if (value !== '') {
          return true;
        }
        return false;
      };
    }
  },
  methods: {
    fileHistoryEvent() {
      this.fileHistory = !this.fileHistory;
    },
    /**
     * 获取聊天记录
     */
    fetchHistory(type = 1) {
      let vm = this;
      let data = {
        caseId: this.$store.state.caseId,
        planId: this.$store.state.planId,
        type
      };
      return this.$http.post('/plan/chatMsgs', data).then((response) => {
        if (type === 1) {
          let oldLength = vm.messageList.length;
          let msgData = JSON.parse(response.data.data);
          let newLength = msgData.length;
          if (oldLength !== newLength) {
            let sendDate = '';
            msgData.forEach((obj) => {
              if (obj.sendDate === sendDate) {
                obj.sendDate = '';
              } else {
                sendDate = obj.sendDate;
              }
              obj.fileType = '';
              if (obj.msgType === 2) {
                let name = obj.fileUrl.split('?')[0].split('.').pop().toLowerCase();
                if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
                  obj.fileType = 'img';
                } else {
                  obj.fileType = 'file';
                }
              }
            });
            vm.messageList = msgData;
            vm.$nextTick(() => {
              vm.scrollToBottom();
            });
          }
        } else {
          // vm.dealFileHistory(JSON.parse(response.data.data));
          this.fileList = JSON.parse(response.data.data);
        }
      });
    },
    // dealFileHistory(data) {
    //   let result = {};
    //   for (let i in data) {
    //     let item = data[i];
    //     let sendTime = item.sendTime;
    //     if (sendTime in result) {
    //       result[sendTime].push(item);
    //     } else {
    //       result[sendTime] = [item];
    //     }
    //   }
    //   this.fileList = result;
    // },
    /**
     * 发送message
     */
    sendMessage(content) {
      let vm = this;
      let param = {
        planId: this.$store.state.planId,
        caseId: this.$store.state.caseId,
        msg: content,
        sendType: 1
      };
      this.$http({
        url: '/plan/sendChatMsg',
        method: 'POST',
        data: param
      }).then((response) => {
        if (response.data.code === 200) {
          vm.fetchHistory();
          vm.message = '';
        }
      });
    },
    /*
    sendFile(param) {
      console.log('param',param)
      let data = param.data;
      let type = param.type;
      console.log(param);
      let vm = this;
      data.append('caseId', this.$store.state.caseId); // 额外参数
      data.append('planId', this.$store.state.planId); // 额外参数
      data.append('sendType', 1); // 额外参数
      this.$http.post('/plan/sendChatFile', data).then((response) => {
        if (response.data.code === 200) {
          vm.fetchHistory();
          if (type === 'img') {
            vm.$store.commit('endImgUpload');
          } else {
            vm.$store.commit('endFileUpload');
          }
        }
      });
    },
    */
    // 修復文件傳送
    sendFile(param) {
      debugger;
      console.log('param', param);
      let data = param.data || param;
      let type = param.type;
      console.log(param);
      let vm = this;
      data.append('caseId', this.$store.state.caseId); // 额外参数
      data.append('planId', this.$store.state.planId); // 额外参数
      data.append('sendType', 1); // 额外参数
      this.$http.post('/plan/sendChatFile', data).then((response) => {
        if (response.data.code === 200) {
          vm.fetchHistory();
          if (type === 'img') {
            vm.$store.commit('endImgUpload');
          } else {
            vm.$store.commit('endFileUpload');
          }
        }
      });
    },
    dateToTimeStr() {
      let da = new Date();
      let t = da.getHours().toString().padStart('0', 2);
      let m = da.getMinutes().toString().padStart('0', 2);
      return `${t}:${m}`;
    },
    scrollToBottom() {
      this.$refs.scroll.wrap.scrollTo({ top: this.$refs.scroll.wrap.scrollHeight });
    }
  },
  watch: {
    '$store.state.planId'() {
      this.fetchHistory();
      this.fetchHistory(2);
    },
    fileHistory() {
      if (this.fileHistory) {
        this.fetchHistory(2);
      } else {
        this.fetchHistory();
      }
    }
  },
  async    mounted() { this.$store.commit('changeCopy',true)
    let vm = this;
    let fetchData = setInterval(() => {
      if (!vm.fileHistory) {
        this.fetchHistory();
      }
      if (vm.fileHistory) {
        this.fetchHistory(2);
      }
    }, vm.setTime);
    await this.fetchHistory();
    this.$nextTick(() => {
      vm.scrollToBottom();
    });
  }
};
</script>
<style lang="less" scoped>
.feedback-box {
  height: 100%;
  background-color: #F0F8FD;
  color: #333;
  .title-box {
    border-bottom: 2px solid #D8D8D8;
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
  }
  .company-title {
    margin-left: 10px;
    line-height: 50px;
  }
  .content {
    padding: 0 0 0 23px;
    border-bottom: 2px solid #D8D8D8;
    color: #ffffff;

    .date-box {
      text-align: center;
      margin-bottom: 6px;
      color: #aaadb1;
      &:first-child {
        margin-top: 15px;
      }
    }
  }
}
.bg-color{
  background-color: #212025;
    color: #aaadb1;
    .title-box{
      border-bottom: 2px solid #454648;
    }
    .content{
      border-bottom: 2px solid #454648;

    }
  
}
</style>